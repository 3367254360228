<template>
  <div class="detail">
    <div class="header flex flex-cross-start">
    </div>
    <div class="block">
      <div class="block-title">退款申请信息</div>
      <div class="row">
        <span class="label">订单金额：</span>
        <span>¥ {{refund?refund.totalFee:0.00}} 元</span>
      </div>
      <div class="row">
        <span class="label">剩余时长：</span>
        <span>{{refund?refund.remainingDay: '无'}} 天</span>
      </div>
      <div class="row">
        <span class="label">预估退款：</span>
        <span>¥ {{refund?refund.estimateRefundMoney : 0}} 元</span>
      </div>
      <div class="row">
        <span class="label">退款原因：</span>
        <span>{{refund?refund.reason : ''}}</span>
      </div>
    </div>

    <div class="block">
      <div class="flex">
        <div class="block-title">退款金额：</div>
        <input class="inputApplyMoney" type="text" name="refundMoney" placeholder="" v-model.trim="refundMoney" v-if="applyMoneyFlag" maxlength="5"/>
        <span style="color:rgba(237, 123, 47, 1)" v-if="!applyMoneyFlag">¥ {{refundMoney?refundMoney:refund?refund.applyMoney:0.00}} 元</span>
        <span>
          <button class="modify" v-if="!applyMoneyFlag" @click="modifyApplyMoney"><img style="width:25%" src="@/assets/update_money.png"/>修改金额</button>
          <button class="modify-sure" v-if="applyMoneyFlag" @click="updateApplyMoney">确定</button>
        </span>
      </div>
      <div class="row" style="color: rgba(0, 0, 0, 0.4000)">
        *退款金额不能超过订单金额
      </div>
    </div>
    <div class="block">
      <div class="block-title">审核意见</div>
      <div class="row">
        <input type="radio" id="one" value="agree" v-model="arRefund">
        <label for="one">同意退款</label>
      </div>
      <div class="row">
        <input type="radio" id="two" value="refuse" v-model="arRefund">
        <label for="two">拒绝退款</label>
      </div>
      <div style="margin-top: 10px">
        <textarea class="text-area" placeholder="请填写审核备注" maxlength="200"
          v-model="memo" style="background-color: #F3F3F3;"/>
      </div>
    </div>
    <div class="block">
      <div class="block-title">退款方式</div>
      <div class="row">
        <input type="radio" id="three" value="SOURCE" v-model="refundType">
        <label for="three">原路返回
        <span style="color:rgba(0, 0, 0, 0.4000)"> (按原订单的支付方式返回)</span>
        </label>
      </div>
      <div class="row">
        <input type="radio" id="four" value="HAND" v-model="refundType">
        <label for="four">人工处理</label>
        <span style="color:rgba(0, 0, 0, 0.4000)"> (由管理员线下处理)</span>
      </div>
    </div>
     <div class="div-button">
      <button class="cancel-button" @click="gotoPage">取消办理</button>
      <button class="check-button" @click="checkRefund">同意办理</button>
    </div>
    <div style="height:100px"></div>
  </div>
</template>
<script>
export default {
    props: ['id', 'refund'],
    data () {
      return {
        refundMoney: null,
        arRefund: 'agree',
        confirmMessage: '成功',
        memo: null,
        info: this.refund,
        refundType: 'SOURCE',
        applyMoneyFlag: false,
        rules: {
          memo: [{ required: true, message: '请填写备注', trigger: 'blur' }],
        }
      }
    },
    created () {
      this.loadInfo()
    },
    methods: {
      updateApplyMoney () {
        var reg = /[^\d.]/g
        if (isNaN(this.refundMoney)) {
          this.$toast.show('请正确填写退款金额')
        } else if (reg.test(this.refundMoney) || this.refundMoney <= 0) {
          this.$toast.show('退款金额必须大于0')
        } else {
          this.applyMoneyFlag = false
        }
      },
       modifyApplyMoney () {
        this.applyMoneyFlag = true
      },
      loadInfo () {
        if (!this.refund && this.id) {
          this.$axios.get(`/h5/refund/${this.id}`).then(res => {
            this.refund = res
          })
        }
      },
      gotoPage () {
        this.$router.go(-1)
      },
      checkRefund () {
        if (this.arRefund === 'agree') {
          this.agreeRefund()
        } else {
          this.refuseRefund()
        }
      },
      agreeRefund () {
        if (!this.memo || this.memo.length < 1 || this.memo.trim() === '') {
          this.$toast.show('请填写备注')
          return
        }
        if (this.memo && this.memo.length > 50) {
          this.$toast.show('备注不能超过50个字符')
          return
        }
        const formData = {
          refundMoney: this.refundMoney ? this.refundMoney : this.refund.applyMoney,
          id: this.refund.id,
          tenantId: this.refund.tenantId,
          refundType: this.refundType,
          money: this.refund.money,
          orderId: this.refund.orderId,
          refundStatus: this.refund.refundStatus,
          memo: this.memo,
          contactNumber: this.refund.contactNumber,
          sn: this.refund.sn,
          orderNo: this.refund.orderNo,
          contact: this.refund.userName,
          reason: this.refund.reason
        }
        if (isNaN(formData.refundMoney)) {
          this.$toast.show('请正确填写退款金额')
          return
        }
        this.$axios.post('/h5/refund/agreeRefund', formData).then(res => {
          if (res) {
            this.confirmMessage = '退款成功'
            this.$router.back()
          } else {
            this.confirmMessage = '退款失败'
          }
          this.$toast.show(this.confirmMessage)
        })
      },
      refuseRefund () {
        if (!this.memo || this.memo.length < 1 || this.memo.trim() === '') {
          this.$toast.show('请填写备注')
          return
        }
        if (this.memo && this.memo.length > 50) {
          this.$toast.show('备注不能超过50个字符')
          return
        }
        this.$axios.put(`/h5/refund/refuse/${this.refund.id}` + '?memo=' + this.memo).then(res => {
          if (res) {
            this.confirmMessage = '操作成功'
            this.$router.back()
          } else {
            this.confirmMessage = '操作失败'
          }
          this.$toast.show(this.confirmMessage)
        })
      }
    }
  }
</script>

<style scoped lang="stylus">
.detail
  background #fff
  padding 32rpx
  height: 150%
  .header
    padding-bottom 32rpx
    border-bottom 1rpx solid rgba(31,31,52,.3)
    font(28,44,$subtext)
    .portrait
      box(144,144)
      margin-right 32rpx
    .model
      font(32,48,$text)
  .block
    border-bottom 1rpx solid rgba(31,31,52,.3)
    padding 32rpx 0
    .block-title
      font(32,48,$text)
    .row
      margin: 16rpx 0
      font(28,44,$text)
      word-break: break-all
      .label
        display inline-block
        width 160rpx
        margin-right 32rpx
        color $subtext
.div-button{
  margin-top: 50px;
  text-align: center;
}
.check-button{
  color: white;
  background-color: #169bd5;
  border: 1px solid #169bd5;
  border-radius:8px;
  font-size: 18px;
  height: 40px;
  width: 120px;
  margin-left: 10px;
}
.cancel-button{
  color: white;
  background-color: #888e91;
  border: 1px solid #888e91;
  border-radius:8px;
  font-size: 18px;
  height: 40px;
  width: 120px;
  margin-right: 10px;
}
.text-area {
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  border: 1px solid rgb(243, 243, 243);
  font-size: 14px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.85);
  overflow: auto;
}
.inputApplyMoney{
  border:1px solid #DCDFE6;
  height:48rpx;
}
.modify{
  margin-left: 80px;
  background:rgb(255 255 255);
  border-radius:24rpx;
  text-align: right ;
  border-color: transparent;
  font(24,40,rgb(102 102 102));
}
.modify-sure{
  margin-left: 10px;
  background:rgb(255 255 255);
  border-radius:24rpx;
  text-align: right ;
  border-color: transparent;
  font(24,40,#1979FF);
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #0000005c
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #0000005c
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #0000005c
}
</style>
